import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_statuses_list = _resolveComponent("statuses-list")!
  const _component_white_block = _resolveComponent("white-block")!
  const _component_page_block = _resolveComponent("page-block")!

  return (_openBlock(), _createBlock(_component_page_block, { breadcrumbs: _ctx.breadcrumbsLink }, {
    "page-blocks-top": _withCtx(() => [
      _createVNode(_component_details_hero, {
        icon: "flag",
        title: "Organization types",
        color: "blue",
        class: "mb-4"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_white_block, { "dark-border": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_statuses_list, {
            "delete-modal-title": "Delete organization status",
            "item-name": "Organization",
            "status-name": "type",
            "status-name-plenty": "types",
            class: "px-8",
            statuses: _ctx.types,
            "palette-items": _ctx.colorPaletteOptionsOutline,
            "onUpdate:statuses": _cache[0] || (_cache[0] = ($event: any) => (_ctx.types = $event))
          }, null, 8, ["statuses", "palette-items"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}